import React from "react"
import { Button, Layout, Row, Col } from 'antd'
import SiteLayout from "../components/SiteLayout";
import JoinUsDetails from "../components/Sections/JoinUsDetails";
import YourData from "../components/Sections/YourData";
import Pitch from "../components/Sections/Pitch";
import Img from 'gatsby-image';

// @ts-ignore
import appstoreIcon from '../assets/appstore.svg'

// @ts-ignore
import googleplayIcon from '../assets/googleplay.svg'

// @ts-ignore
import styles from './pilotkunde.module.scss';
import { StaticQuery, graphql } from "gatsby";
import { PhoneEmulatorType } from "../components/PhoneEmulatorV2";
import AppStoreButton from "../components/Buttons/AppStoreButton";
import GooglePlayButton from "../components/Buttons/GooglePlayButton";

export default () => (
    <StaticQuery
        query={graphql`
        {
             testbruker: file(relativePath: {eq: "testbruker1.jpg"}) {
                childImageSharp{
                 fluid(maxWidth: 680) {
                    ...GatsbyImageSharpFluid
                 }
               }
             }
           }           
      `
        }
        render={data => (
            <SiteLayout>
                <div className={styles.pagecontainer}>
                    <div className={styles.textcontainer}>
                        <div className={styles.header}>Vil du bli en av&nbsp;Oss?</div>

                        <div className={styles.introtext}>
                            Vi er i mål med antall testbrukere vi trenger for å levere på Enova prosjektet. De som registrer seg i appen nå, vil være de første som får tilbud om å bli pilotkunde. Vi jobber nå med å kunne tilby Oss-brikken og tjenesten&nbsp;kommersielt. 
                            <br />
                            Mer informasjon kommer.
                            <Row style={{ marginLeft: "auto", marginRight: "auto", textAlign: "center", maxWidth: 330 }} type="flex" justify="space-around" align="middle">
                                <Col xs={24} sm={12} style={{ marginTop: 24 }}><AppStoreButton /></Col>
                                <Col xs={24} sm={12} style={{ marginTop: 24 }}><GooglePlayButton /></Col>
                            </Row>
                        </div>
                        <div className={styles.imagecontainer}>
                            <Img title="Oss-Testbruker" fluid={data.testbruker.childImageSharp.fluid} className={styles.image} alt="Oss testbruker" />
                        </div>
                        <div className={styles.introsubtext}>
                            Alle får tilsendt Oss-Brikken som monteres i den nye strømmåleren i sikringsskapet, og Oss-appen synkroniserer enkelt mobilen og strømmåleren. Nå har du fullstendig oversikt over hjemmet ditt, men det er bare&nbsp;begynnelsen.
                            <br /> <br />
                            I tillegg til å være blant de første i landet som får prøve Oss-brikken, vil du få være med på å utvikle spennende, nye tjenester til den. Vi kjører jevnlig ut nye funksjoner, ber om ærlige tilbakemeldinger, og videreutvikler tjenesten basert på disse innspillene. Du får rett og slett muligheten til å være en del av et utvidet&nbsp;Oss-team.
                        </div>
                    </div>
                </div>
                <JoinUsDetails moreButton={false} emulatorType={PhoneEmulatorType.Intro} />
                <YourData topVisual={true} moreButton={true} />
                <Pitch />
            </SiteLayout>
        )}
    />
)